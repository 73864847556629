import Title from 'component/title/TitleComponent';
import Footer from 'component/footer/FooterComponent';
import Transfer from 'component/transfer/TransferComponent';

import style from './GlobalComponent.module.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import banner1 from 'assets/global/banners/banner1.png'
import banner1Btn from 'assets/global/banners/banner1_btn.png'
import banner2 from 'assets/global/banners/banner2.png'
import banner2Btn from 'assets/global/banners/banner2_btn.png'

import region1 from 'assets/global/regions/1.png'
import region2 from 'assets/global/regions/2.png'
import region3 from 'assets/global/regions/3.png'
import region4 from 'assets/global/regions/4.png'
import region5 from 'assets/global/regions/5.png'
import region6 from 'assets/global/regions/6.png'
import region7 from 'assets/global/regions/7.png'
import region8 from 'assets/global/regions/8.png'
import region9 from 'assets/global/regions/9.png'
import region10 from 'assets/global/regions/10.png'

import service1 from 'assets/global/services/1.png';
import service2 from 'assets/global/services/2.png';
import service3 from 'assets/global/services/3.png';
import service4 from 'assets/global/services/4.png';
import service5 from 'assets/global/services/5.png';
import service6 from 'assets/global/services/6.png';

import reason from 'assets/global/why/reason.png'

import voiceBg from 'assets/global/why/bg.png'
import voiceTitle from 'assets/global/why/title.png'
import btn from 'assets/global/why/btn.png'
import btn_hover from 'assets/global/why/btn_hover.png'

import voices from 'assets/global/why/voices.png';

import qrcode from 'assets/global/qrcode.png';


import { useState, useEffect, createRef, useRef } from 'react'
import { Helmet } from 'react-helmet';

import { Carousel } from 'antd';


const Global = (props) => {
    const { clickMenu, showConsultModal } = props;

    const width = window.innerWidth;
    const [x, setX] = useState(1);
    const swiper = useRef()

    const regions = [region1, region2, region3, region4, region5, region6, region7, region8, region9, region10];
    const services = [service1, service2, service3, service4, service5, service6];

    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };


    useEffect(() => {
        // 组件挂载完成时的逻辑
        console.log(`Component mounted with props:`);
        const intervalId = setInterval(() => {
            setX(x => x + 1);
        }, 3000);

        // 清理函数
        return () => {
            console.log(`Component will unmount`);
            clearInterval(intervalId)
        };
    }, []);



    return (
        <div>
			<Helmet>
				<title>冲鸭教育官网_全球留学_留学机构</title>
				<meta name="description" content="冲鸭教育是一家结合语言培训+出国留学等一站式多元规划服务企业,致力于打造专业、好玩、实用、负责的一流线上等小语种教育，及提供美国/加拿大/英国/爱尔兰/新加坡/德国/新西兰/迪拜/日本/韩国/法国等及地区的留学中介等国际教育全产业链服务." />
				<meta name="keywords" content="全球留学、留学机构、韩国留学、日本留学、法国留学" />
			</Helmet>
            <img className='qrcode' src={qrcode} alt='' />

            <div className={style.carousel_box}>
                <Carousel autoplay>
                    <div className={style.single_carousel}>
                        <img src={banner1} alt="" />
                        <img className={style.banner_btn} src={banner1Btn} onClick={showConsultModal} alt='' />
                    </div>
                    <div className={style.single_carousel}>
                        <img src={banner2} alt="" />
                        <img className={style.banner_btn} src={banner2Btn} onClick={showConsultModal} alt='' />
                    </div>
                </Carousel>
            </div>

            <Title
                className={style.title_box}
                mtX='0.83vw'
                title={'Regions'}
                subtitle={'留学地区'}
                aline='center' />
            <div className={style.regions_box}>
                {regions.map((item, i) => {
                    return <div className={style.single_resion} key={i} >
                        <img src={item} alt='' />
                    </div>
                })}
            </div>

            <Transfer text1='如何选择最佳目的地？' text2='咨询专业老师' showConsultModal={showConsultModal} />

            <Title
                className={style.title_box}
                mtX='0.83vw'
                title={'Our Services'}
                subtitle={'我们的服务'}
                aline='center' />
            <div className={style.services_box}>
                {services.map((item, i) => {
                    return <div className={style.single_service} key={i} >
                        <img src={item} alt='' />
                    </div>
                })}
            </div>


            <Title
                className={style.title_box}
                mtX='0.83vw'
                title={'Why choose“Chongya”'}
                subtitle={'为什么选择冲鸭'}
                aline='center' />

            <div className={style.reason_box}>
                <img src={reason} alt="" />
            </div>

            <div className={style.voice_box} style={{
                backgroundImage: `url(${voiceBg})`,
            }}>
                <div className={style.voice_top_box}>
                    <div className={style.voice_top_left_box}>
                        <img src={voiceTitle} alt="" />
                    </div>

                    <div className={style.voice_top_right_box}>
                        <img src={voices} alt="" className={style.sliding_image} />
                    </div>
                </div>

                <div className={style.img_box} onClick={showConsultModal} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <img src={!isHover ? btn : btn_hover} alt='' />
                </div>
            </div>



            <div>
                <Footer type='global' />
            </div>
        </div >
    );
};

export default Global;