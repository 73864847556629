import './App.css';
import 'swiper/css';

import Header from './component/header/HeaderComponent';


import nowRoute from './redux/store'


import Japan from './page/Japan/JapanComponent';
import Course from './page/Course/CourseComponent';
import Team from './page/Team/TeamComponent';
import About from './page/About/AboutComponent';
import Korean from './page/Korean/KoreanComponent';
import Franch from './page/Franch/FranchComponent';
import Global from './page/Global/GlobalComponent'

import modalPc from './assets/modalPc.png'
import modalMobile from './assets/modalMobile.png'
import moreBtn from './assets/index/more.png'
import leftBottomLogo from './assets/index/logo_left_bottom.png'
import japanLogo from './assets/index/japan_logo.png'
import koreanLogo from './assets/index/korean_logo.png'
import franchLogo from './assets/index/franch_logo.png'
import globalLogo from './assets/index/global_logo.png'

import japanLogoM from './assets/index/japan_logo_mobile.png'
import koreanLogoM from './assets/index/korean_logo_mobile.png'
import franchLogoM from './assets/index/franch_logo_mobile.png'
import globalLogoM from './assets/index/global_logo_mobile.png'

import touchLogo from 'assets/index/logo_touch.png'

import japanModalPc from './assets/japanModalPc.png'
import japanModalMobile from './assets/japanModalMobile.png'
import koreanModalPc from './assets/koreanModalPc.png'
import koreanModalMobile from './assets/koreanModalMobile.png'
import globalModalPc from './assets/globalModalPc.png'
import globalModalMobile from './assets/globalModalMobile.png'

import auditionPc from './assets/auditionPc.png'
import auditionMobile from './assets/auditionMobile.png'

import closeBtn from './assets/close.png'


import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import title from './assets/index/title.png'
import titleM from './assets/index/titleM.png'
import { Helmet } from 'react-helmet';

function App() {

    //测试弹窗，先取消 
    const [showMask, setShowMask] = useState(false);


    const [showConsult, setShowConsult] = useState(false);
    const [showKoreanConsult, setShowKoreanConsult] = useState(false);
    const [showGlobalConsult, setShowGlobalConsult] = useState(false);
    const [showAudition, setShowAudition] = useState(false);

    const [onJapan, setOnJapan] = useState(false);
    const [onKorean, setOnKorean] = useState(false);
    const [onFranch, setOnFranch] = useState(false);
    const [onGlobal, setOnGlobal] = useState(false);

    const width = window.innerWidth;

    const clickMenu = (str) => {
        let route = str;
        if (str === 'app') {
            route = 'japan';
        } else if (str === 'KoreanApp') {
            route = 'hanyu';
        } else if (str === 'FranchApp') {
            route = 'franch';
        }
        // 更新地址栏，不会刷新页面
        window.history.pushState(null, '', `/${route}`);

        // 根据不同的逻辑执行滚动或其他操作
        if (str === 'app') {
            nowRoute.dispatch({ type: 'japan' });
            setTimeout(() => {
                const jpElement = document.getElementById('jpapp');
                if(jpElement) {
                    window.scrollTo(0, jpElement.offsetTop);
                }
            });
            return;
        }
        if (str === 'KoreanApp') {
            nowRoute.dispatch({ type: 'hanyu' });
            setTimeout(() => {
                const krElement = document.getElementById('krapp');
                if(krElement) {
                    window.scrollTo(0, krElement.offsetTop);
                }
            });
            return;
        }
        if (str === 'FranchApp') {
            nowRoute.dispatch({ type: 'franch' });
            setTimeout(() => {
                const frElement = document.getElementById('frapp');
                if(frElement) {
                    window.scrollTo(0, frElement.offsetTop);
                }
            });
            return;
        }
        nowRoute.dispatch({ type: str });
        window.scrollTo(0, 0);
    };

    const showAuditionModal = () => {
        // window.open('https://work.weixin.qq.com/kfid/kfcaf8715a762dd294b', '_blank')
        setShowConsult(true);

        // setShowAudition(true);
    }

    const showConsultModal = () => {
        window.open('https://work.weixin.qq.com/kfid/kfcaf8715a762dd294b', '_blank')

    }

    const showJapanModal = () => {
        setShowConsult(true);
    }

    const showKoreanModal = () => {
        setShowKoreanConsult(true)
    }

    const showGlobalModal = () => {
        setShowGlobalConsult(true)
    }

	// 新增 useEffect：初始化时根据 URL 设置路由，同时监听 popstate 事件
	useEffect(() => {
	    const validRoutes = ['index', 'japan', 'hanyu', 'franch', 'about', 'global'];
	    const path = window.location.pathname.slice(1); // 去掉前导 '/'
	    const route = validRoutes.includes(path) ? path : 'index';
	    nowRoute.dispatch({ type: route });
	    // 如果路径为空或不匹配，则更新地址栏为默认路由
	    if (!path || !validRoutes.includes(path)) {
	        window.history.replaceState(null, '', `/${route}`);
	    }
	
	    const handlePopState = () => {
	        const newPath = window.location.pathname.slice(1);
	        const newRoute = validRoutes.includes(newPath) ? newPath : 'index';
	        nowRoute.dispatch({ type: newRoute });
	    };
	
	    window.addEventListener('popstate', handlePopState);
	    return () => {
	        window.removeEventListener('popstate', handlePopState);
	    };
	}, []);

    useEffect(() => {
        // 组件挂载完成时的逻辑
        console.log(`Component mounted with props:`);

        setTimeout(() => {
            setShowMask(false)
        }, 4000)

        // 清理函数
        return () => {
            console.log(`Component will unmount`);
        };
    }, []);

    return (
        <div className="App bgf6f7f9">
            <Header clickMenu={clickMenu}></Header>
			<Helmet>
				<title>冲鸭教育官网_冲鸭日语_冲鸭韩语_日本留学_韩国留学</title>
				<meta name="description" content="冲鸭教育是一家以硕博名师为特色，自有研发冲鸭日语，冲鸭韩语等小语种课程内容体系的“互联网+教育”创新科技企业。致力于打造专业、好玩、实用、负责的一流线上等小语种教育课堂。专业提供日语、韩语等考试培训，全球留学指导和兴趣学习。" />
				<meta name="keywords" content="冲鸭日语学习,冲鸭韩语学习,冲鸭教育,jlpt考试,topik考试,日语高考,日语培训,韩语培训,日语老师,韩语老师,日本留学,韩国留学,topik3,topik4,topik5,topik6,N1,N2,N3,N4,N5小语种学习、法语学习、全球留学机构" />
			</Helmet>
            {
                nowRoute.getState() === 'index' &&
                <div className='bg'>
                    <div className='index-title'>
                        <img src={width > 820 ? title : titleM} alt='' />
                    </div>
                    <div className='guide-box'>
                        <div className='guide-logo' onMouseMove={() => {
                            setOnJapan(false);
                            setOnFranch(false);
                            setOnKorean(false);
                            setOnGlobal(true);
                        }} onMouseLeave={() => {
                            setOnFranch(false)
                        }}>
                            <img src={width > 820 ? globalLogo : globalLogoM} alt='' onClick={() => {
                                nowRoute.dispatch({ type: 'global' });
                            }} />
                            {width > 820 && onGlobal && <img src={touchLogo} className='touch_logo' alt='' />}
                        </div>
                        <div className='guide-logo' onMouseMove={() => {
                            setOnJapan(true);
                            setOnFranch(false);
                            setOnKorean(false);
                            setOnGlobal(false);
                        }} onMouseLeave={() => {
                            setOnJapan(false)
                        }} style={{
                            left: '16vw'
                        }}>
                            <img src={width > 820 ? japanLogo : japanLogoM} alt='' onClick={() => {
                                nowRoute.dispatch({ type: 'japan' });
                            }} />
                            {width > 820 && onJapan && <img src={touchLogo} className='touch_logo' alt='' />}
                        </div>
                        <div className='guide-logo' onMouseMove={() => {
                            setOnJapan(false);
                            setOnFranch(false);
                            setOnKorean(true);
                            setOnGlobal(false);
                        }} onMouseLeave={() => {
                            setOnKorean(false)
                        }} style={{
                            left: '32vw'
                        }}>
                            <img src={width > 820 ? koreanLogo : koreanLogoM} alt='' onClick={() => {
                                nowRoute.dispatch({ type: 'hanyu' });
                            }} />
                            {width > 820 && onKorean && <img src={touchLogo} className='touch_logo' alt='' />}
                        </div>
                        <div className='guide-logo' onMouseMove={() => {
                            setOnJapan(false);
                            setOnFranch(true);
                            setOnKorean(false);
                            setOnGlobal(false);
                        }} onMouseLeave={() => {
                            setOnFranch(false)
                        }} style={{
                            left: '48vw'
                        }}>
                            <img src={width > 820 ? franchLogo : franchLogoM} alt='' onClick={() => {
                                nowRoute.dispatch({ type: 'franch' });
                            }} />
                            {width > 820 && onFranch && <img src={touchLogo} className='touch_logo' alt='' />}
                        </div>
                    </div>
                    {!onJapan && !onFranch && !onKorean && <img src={leftBottomLogo} className='left-bottom-logo' alt='' />}
                    <img src={moreBtn} className='more-btn' alt='' onClick={() => {
                        nowRoute.dispatch({ type: 'about' });
                    }} />
                </div>
            }


            {
                showMask && <div className='mask'>
                    <img className='modal' src={width > 820 ? modalPc : modalMobile} alt='' />
                    <img className='modal_close' src={closeBtn} alt='' onClick={() => {
                        setShowMask(false);
                    }} />
                </div>}

            {
                showKoreanConsult && <div className='mask'>
                    <div className='modal bigModal' style={{
                        backgroundImage: `url(${width > 820 ? koreanModalPc : koreanModalMobile})`,
                        backgroundSize: '100% 100%'
                    }}>
                        <img className='modal_close' src={closeBtn} alt='' onClick={() => {
                            setShowKoreanConsult(false);
                        }} />
                    </div>
                </div>}

            {
                showGlobalConsult && <div className='mask'>
                    <div className='modal bigModal' style={{
                        backgroundImage: `url(${width > 820 ? globalModalPc : globalModalMobile})`,
                        backgroundSize: '100% 100%'
                    }}>
                        <img className='modal_close' src={closeBtn} alt='' onClick={() => {
                            setShowGlobalConsult(false);
                        }} />
                    </div>
                </div>}

            {
                showConsult && <div className='mask'>
                    <div className='modal bigModal' style={{
                        backgroundImage: `url(${width > 820 ? japanModalPc : japanModalMobile})`,
                        backgroundSize: '100% 100%'
                    }}>
                        <img className='modal_close' src={closeBtn} alt='' onClick={() => {
                            setShowConsult(false);
                        }} />
                    </div>
                </div>}

            {
                showAudition && <div className='mask'>
                    <img className='modal bigModal' src={width > 820 ? auditionPc : auditionMobile} alt='' />
                    <img className='modal_close' src={closeBtn} alt='' onClick={() => {
                        setShowAudition(false);
                    }} />
                </div>}




            {
                nowRoute.getState() === 'japan' && <Japan clickMenu={clickMenu} showAuditionModal={showAuditionModal} showConsultModal={showJapanModal} />
            }

            {
                nowRoute.getState() === 'about' && <About clickMenu={clickMenu} showConsultModal={showConsultModal} />
            }

            {
                nowRoute.getState() === 'hanyu' && <Korean showAuditionModal={showAuditionModal} showConsultModal={showKoreanModal} />
            }

            {
                nowRoute.getState() === 'franch' && <Franch showAuditionModal={showAuditionModal} />
            }

            {
                nowRoute.getState() === 'global' && <Global showConsultModal={showGlobalModal} />
            }
        </div>
    );
}

export default App;
