import { React, useState } from 'react';
import style from './HeaderComponent.module.css';
import logo from 'assets/logo.png'
import { Menu, Dropdown } from 'antd';

import btnMobile from 'assets/btn-mobile.png'

import nowRoute from 'redux/store';




nowRoute.subscribe(() => {
    console.log(nowRoute.getState());
})
const Header = (props) => {
    const { clickMenu } = props;


    const menuComponent = (
        <Menu>
            <Menu.Item>
                <div onClick={() => {
                    clickMenu('global')
                }}>全球留学</div>
            </Menu.Item>
            <Menu.Item>
                <div onClick={() => {
                    clickMenu('japan');
                }}>冲鸭日语</div>
            </Menu.Item>
            <Menu.Item>
                <div onClick={() => {
                    clickMenu('hanyu')
                }}>冲鸭韩语</div>
            </Menu.Item>
            <Menu.Item>
                <div onClick={() => {
                    clickMenu('franch')
                }}>冲鸭法语</div>
            </Menu.Item>
            <Menu.Item>
                <div onClick={() => {
                    clickMenu('about')
                }}>关于我们</div>
            </Menu.Item>
            <Menu.Item>
                <div onClick={() => {
                    clickMenu('app')
                }}>日语App</div>
            </Menu.Item>
            <Menu.Item>
                <div onClick={() => {
                    clickMenu('KoreanApp')
                }}>韩语App</div>
            </Menu.Item>
            <Menu.Item>
                <div onClick={() => {
                    clickMenu('FranchApp')
                }}>法语App</div>
            </Menu.Item>

        </Menu>
    )

    const goAppComponent = (
        <Menu>
            <Menu.Item>
                <div onClick={() => {
                    clickMenu('app')
                }}>日语App</div>
            </Menu.Item>
            <Menu.Item>
                <div onClick={() => {
                    clickMenu('KoreanApp')
                }}>韩语App</div>
            </Menu.Item>
            <Menu.Item>
                <div onClick={() => {
                    clickMenu('FranchApp')
                }}>法语App</div>
            </Menu.Item>
        </Menu>
    )


    const width = window.innerWidth;

    return (
        <div className={style.App_header}>
            <div className={style.header_box}>
                <header className={style.header} id='home'>
                    <img id='logo' src={logo} alt='logo' onClick={() => {
                        clickMenu('index');
                    }} />
                    <div className={style.title_box} style={{
                        display: width > 820 ? 'flex' : 'none'
                    }}>
                        <div onClick={() => {
                            clickMenu('global')
                        }} className={nowRoute.getState() === 'global' ? style.head_title_active : ''}>全球留学</div>
                        <div onClick={() => {
                            clickMenu('japan');
                        }} className={nowRoute.getState() === 'japan' ? style.head_title_active : ''}>冲鸭日语</div>
                        <div onClick={() => {
                            clickMenu('hanyu')
                        }} className={nowRoute.getState() === 'hanyu' ? style.head_title_active : ''}>冲鸭韩语</div>
                        <div onClick={() => {
                            clickMenu('franch')
                        }} className={nowRoute.getState() === 'franch' ? style.head_title_active : ''}>冲鸭法语</div>
                        <div onClick={() => {
                            clickMenu('about')
                        }} className={nowRoute.getState() === 'about' ? style.head_title_active : ''}>关于我们</div>
                        <Dropdown overlay={goAppComponent}>
                            <div>APP下载</div>
                        </Dropdown>
                    </div>
                    <Dropdown overlay={menuComponent}>
                        <img className={style.menu} src={btnMobile} alt='menu' style={{
                            display: width > 820 ? 'none' : 'block'
                        }} />
                    </Dropdown>
                </header>
            </div>
        </div>
    );
};

export default Header;