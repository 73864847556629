import style from './FranchComponent.module.css';

import AboutChild from '../About/component/aboutChild/AboutChildComponent'
import Services from './component/service/serviceComponent';
import Audition from './component/audition/AuditionComponent';
import FranchApp from './component/franchApp/franchAppComponent';

import Transfer from 'component/transfer/TransferComponent';

import hightlight1 from 'assets/franch/hightlights/1.png';
import hightlight2 from 'assets/franch/hightlights/2.png';
import hightlight3 from 'assets/franch/hightlights/3.png';

import qrcode from 'assets/franch/qrcode.png';
import banner from 'assets/banner/franchbanner.png'
import line from 'assets/banner/line2.png'
import data1 from 'assets/franch/data/1.png';
import data2 from 'assets/franch/data/2.png';
import data3 from 'assets/franch/data/3.png';

import Footer from 'component/footer/FooterComponent';
import DatasCom from './component/datas/datasComponent';
import Product from './component/product/productComponent';
import { Helmet } from 'react-helmet';

const datas = [data1, data2, data3];
const hightlights = [hightlight1, hightlight2, hightlight3];


const Franch = (props) => {
    const { clickMenu, showAuditionModal } = props;
    const showConsultModal = () => {
        //window.open('https://work.weixin.qq.com/kfid/kfc83ed383947cd59b9', '_blank')
        console.log('do nothing')
    }
    return (
        <div>
			<Helmet>
				<title>冲鸭法语教育官网_法语学习_法语培训_法国留学</title>
				<meta name="description" content="冲鸭教育专注于日韩法语学习领域，为法语培训、法语学习、日语培训、韩语学习以及英语薄弱的高考生与考研学生提供一站式专业解决方案。冲鸭教育致力于培养听说读写全能型人才与行业实战应用人才，通过打造专业、实用、轻松的线上课堂，结合严谨的教学质量，让每位学员在寓教于乐的学习环境中实现全面发展。在法语教育方面，我们不仅帮助学员扎实语言基础，更为未来法国留学做好充分准备。" />
				<meta name="keywords" content="冲鸭法语,冲鸭教育,jlpt考试,topik考试,法语高考,法语培训,法语老师,法国留学,topik3,topik4,topik5,topik6,N1,N2,N3,N4,N5,小语种学习、法语学习、全球留学、留学机构" />
			</Helmet>
            {/* <img className='qrcode' src={qrcode} alt='' /> */}

            <div className='carousel_box'>
                <div className='carousel'>
                    <img src={banner} alt='' />
                </div>
                <img className='carousel_line' alt='' src={line}></img>
            </div>
            <Product />
            <AboutChild
                title='Points forts du cours'
                subtitle='课程亮点'
                imgs={hightlights}
                isHome='true'
            />



            {/* <Transfer text1='立即报名' text2='立即报名' showConsultModal={showConsultModal} /> */}
            <Services />

            {/* <Audition showAuditionModal={showAuditionModal} /> */}

            {/* <FranchApp /> */}


            <DatasCom
                title="Obtenez gratuitement des"
                title2="ressources d'apprentissage riches"
                subtitle='免费获取丰富学习资料'
                imgs={datas}
                isHome='true'
                showConsultModal={showConsultModal}
            />
            {/* <Transfer text1='立即免费下载' text2='立即免费下载' showConsultModal={showConsultModal} /> */}
            <Footer type='franch' />
        </div>
    );
};

export default Franch;